/**
 * Loan Module
 */

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  setUpdating(state, value) {
    state.updating = value;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  loaded(state, value) {
    state.data = value.data;

    state.loading = false;
    state.loaded = true;
  },
  loading(state) {
    let template = initialize();
    template['loading'] = true;
    for (let key in template) state[key] = template[key];
  },
  init(state) {
    Object.assign(state, initialize());
  },
};

// helpers
function initialize() {
  return {
    data: null,
    loading: false,
    loaded: false,
    updating: false,
  };
}

export default {
  state: initialize,
  getters,
  actions,
  mutations,
  namespaced: true,
};
