<template>
  <div id="app">
    <router-view />
    <notifications group="api" position="top right" :ignoreDuplicates="true" />
    <UpdateApp :show="showRefreshAppDialog"></UpdateApp>
    <transition name="fade">
      <div v-if="loaded && !isContactView" class="floating-help-btn">
        <router-link :to="'/contact'" class="link-button contact-button"
          ><i class="ph-lifebuoy is-size-5"></i>
          {{ $t("message.session.contactLink") }}</router-link
        >
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UpdateApp from "@/components/dialogs/UpdateApp";

export default {
  components: {
    UpdateApp,
  },
  data() {
    return {
      showRefreshAppDialog: false,
      loaded: false,
    };
  },
  computed: {
    ...mapState({
      refreshApp: (state) => state.version.refreshApp,
    }),
    isContactView() {
      return this.$route.name === "contact";
    },
  },
  mounted() {
    setTimeout(() => (this.loaded = true), 1000);
  },
  watch: {
    refreshApp: {
      immediate: true,
      handler(value) {
        if (value) {
          setTimeout(() => (this.showRefreshAppDialog = value), 1000);
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.floating-help-btn {
  background: white;
  border-radius: 50px;
  padding: 0.25rem 1rem;
  position: fixed;
  bottom: 40px;
  right: 50px;
  border: 1px solid $grey-lighter;

  a {
    font-size: 14px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>