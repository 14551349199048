import Vue from 'vue';
import VueRouter from 'vue-router';
import Nprogress from 'nprogress';
import { i18n } from '@/main';
import { isLogged } from '@/helpers/helpers';

import noAuth from './noAuth';
import auth from './auth';
import secured from './secured';

Vue.use(VueRouter);

const routes = [...noAuth, ...auth, secured];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// navigation guards before each
router.beforeEach(async (to, from, next) => {
  Nprogress.start();
  document.title = `${i18n.t(to.meta.title)} | ProBroker`;

  if (to.meta.auth) {
    // Route requires auth, check if logged in, if not, redirect to login.
    if (!isLogged()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      // Check user permissions to access route
      // const routes = store.getters['appdata/routes'];

      // const onboarding =
      //   store.getters['auth/onboarded'] === false && to.path !== '/onboarding';

      // if (routes.length) {
      //   !routes.includes(to.meta.access)
      //     ? next({ path: '/error' })
      //     : !onboarding
      //     ? next()
      //     : next({ path: '/onboarding' });
      // } else {
      //   try {
      //     const response = await api.post('user/permission/validate', {
      //       permission: to.meta.access,
      //     });

      //     if (response.data.success) {
      //       !onboarding ? next() : next({ path: '/onboarding' });
      //     }
      //   } catch (error) {
      //     next({ path: '/error' });
      //   }
      // }

      next();
    }
  } else {
    // Do not show login pages in case user is already logged in
    if (to.matched.some((record) => record.meta.isLogin)) {
      if (isLogged()) next({ path: '/leads' });
    }

    next();
  }
});

// navigation guard after each
router.afterEach(() => {
  Nprogress.done();
});

export default router;
