/**
 * Loan Module
 */
import Vue from 'vue';
import api from '@/api';
import Nprogress from 'nprogress';
import { i18n } from '@/main';
import { downloadBlob } from '@/helpers/helpers';

// getters
const getters = {};

// actions
const actions = {
  async download(context, payload) {
    Nprogress.start();

    try {
      const response = await api.get(
        'applicant/' + payload.applicant + '/document/' + payload.doc,
        {
          responseType: 'blob',
        }
      );

      downloadBlob(payload.name, response.data);
    } catch (error) {
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.shared.downloadError'),
      });
    } finally {
      Nprogress.done();
    }
  },
  async downloadZip(context, payload) {
    Nprogress.start();

    try {
      const response = await api.get(
        'applicant/' + payload.applicant + '/document',
        {
          responseType: 'blob',
        }
      );

      downloadBlob(payload.name, response.data);
    } catch (error) {
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.shared.downloadError'),
      });
    } finally {
      Nprogress.done();
    }
  },
};

// mutations
const mutations = {
  loading(state) {
    let template = initialize();
    template['loading'] = true;
    for (let key in template) state[key] = template[key];
  },
  loaded(state, value) {
    state.loading = false;
    state.loaded = true;

    state.list = value.list;
  },
};

// helpers
function initialize() {
  return {
    list: {},
  };
}

export default {
  state: initialize,
  getters,
  actions,
  mutations,
  namespaced: true,
};
