import es from './es';
import esValidationMessages from 'vee-validate/dist/locale/es';

// Add custom rules validation messages
esValidationMessages.messages['password'] =
  'La contraseña debe tener un mínimo de 6 caracteres y al menos una letra y un número';
esValidationMessages.messages['spanishId'] =
  'Número de identificación no válido';
esValidationMessages.messages['generalId'] =
  'Número de identificación no válido';
esValidationMessages.messages['phone'] =
  'El formato del teléfono es incorrecto';

export default {
  es: {
    message: es,
    validations: esValidationMessages,
  },
};
