export default {
  login: 'Inicio de sesión',
  loginIn: 'Iniciando sesión',
  loginLink: 'Inicia sesión',
  loginInstructions:
    'Ingrese nombre de usuario y contraseña para acceder al panel de control de ProBroker',
  loginSubmit: 'Iniciar sesión',
  loginErrorWrongUserPassword:
    'Error al iniciar sesión, por favor comprueba tu usuario y contraseña',
  loginErrorInactiveUser:
    'Error al iniciar sesión, el usuario no ha sido activado',
  loginErrorInactiveSubscription:
    'Error al iniciar sesión, la suscripción está inactiva',
  loginErrorNotApproved:
    'Error al iniciar sesión, tu usuario no ha sido validado',
  loginErrorBadConfig: 'Error en la configuración de la cuenta',
  loginRequestError: 'Error al iniciar sesión',
  loginTokenError: 'Error al iniciar sesión, comprueba que el token es válido.',
  loginNoTokenError: 'Token not found',
  logout: 'Cerrando sesión',
  logoutRequestError: 'Error al intentar cerrar sesión',
  signedOut: 'Sesión cerrada',
  expired: 'Sesión expirada',
  signup: 'Completa tu registro',
  signupSubmit: 'Registrarme',
  signupOrganizationSubmit: 'Crear',
  signupErrorEmailExists: 'Ya existe un usuario con el email indicado',
  signupErrorIDExists:
    'Ya existe un usuario con el número de identificación indicado',
  signupErrorCompanyIDExists: 'Ya existe una organización con el CIF indicado',
  signupRequestError: 'Error al crear la cuenta',
  forgotPasswordSubmit: 'Enviar email',
  forgotPasswordTitle: 'Recuperar contraseña',
  forgotPasswordSubtitle:
    'Ingresa tu correo electrónico y te enviaremos un enlace para reestablecerla',
  forgotPasswordError: 'Ocurrió un error en el proceso',
  forgotPasswordSuccess:
    'En breve recibirás un correo electrónico con el enlace',
  forgotPasswordNotActive: 'El usuario no está activo',
  forgotPasswordInactiveUser: 'El usuario no está activo',
  forgotPasswordNotFound: 'No existe ninguna cuenta con el email indicado',
  forgotPasswordEmailError:
    'Ocurrió un error al enviar el correo con el enlace',
  resetPasswordTitle: 'Restablecer la contraseña',
  resetPasswordSubtitle: 'Ingresa la nueva contraseña para tu cuenta',
  resetPasswordWrongToken: 'El enlace no es válido',
  resetPasswordSubmit: 'Cambiar contraseña',
  resetPasswordSuccess: 'Contraseña actualizada',
  resetPasswordError: 'Ocurrió un error al realizar el cambio de contraseña',
  changePasswordTitle: 'Establecer contraseña',
  changePasswordSubtitle:
    'Establece la contraseña con la que quieras acceder a tu cuenta de prohipotecas',
  changePasswordWrongToken: 'El enlace no es válido',
  changePasswordSubmit: 'Guardar contraseña',
  changePasswordSuccess: 'Contraseña actualizada',
  changePasswordError: 'Ocurrió un error al realizar el cambio de contraseña',
  email: 'Email',
  username: 'Usuario',
  password: 'Contraseña',
  name: 'Nombre y Apellidos',
  confirmPassword: 'Confirmar contraseña',
  idType: 'Tipo de identificación',
  idNumber: 'Número de identificación',
  phone: 'Teléfono',
  address: 'Dirección',
  postalCode: 'Código Postal',
  companyName: 'Nombre de la organización',
  companyIdentification: 'NIF / CIF',
  licenseNumber: 'Número de licencia',
  city: 'Ciudad',
  employeeIdentification: 'Identificador del asesor',
  privacyPolicy: 'Política de privacidad',
  termsAndConditions: 'Términos y condiciones',
  agreePrivacyPolicy1: 'He leído y acepto la',
  agreePrivacyPolicy2: 'política de privacidad',
  agreeTermsAndConditions1: 'He leído y acepto los',
  agreeTermsAndConditions2: 'términos y condiciones',
  termsTitle: 'Términos y condiciones',
  privacyTitle: 'Política de privacidad',
  forgotPassword: '¿Has olvidado tu contraseña?',
  yetNoAccount: '¿Aún no tienes cuenta? Crea una nueva aquí',
  haveAnAccount: '¿Ya tienes una cuenta?',
  backToLogin: 'Volver a inicio de sesión',
  accept: 'Acepto',
  role: 'Tipo de usuario',
  initialWallet: 'Crédito inicial',

  // Contact page
  contactTitle: 'Contacta con nosotros',
  contactContent: 'Mensaje',
  contactSuccess:
    'Se ha enviado el mensaje correctamente. Te responderemos lo antes posible.',
  contactError: 'Ha ocurrido un error al intentar enviar el mensaje',
  contactWithUs: 'Contacta con nosotros',
  contactLink: '¿Preguntas? Contáctanos',
  hiddenWebsite: 'Página web'
};
