export default {
  home: {
    to: '/home',
    name: 'home',
  },
  leads: {
    to: '/leads',
    name: 'leads',
  },
  loans: {
    to: '/loans',
    name: 'loans',
  },
  loan: {
    to: '/loan',
    name: 'loan-detail',
  },
  broker: {
    to: '/broker/dashboard',
    name: 'broker-create',
  },
  user: {
    name: 'user',
    to: '/user',
  },
  settings: {
    name: 'settings',
    to: '/settings',
  },
};
