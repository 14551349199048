export const languages = [
  {
    name: 'Spanish',
    icon: 'es',
    locale: 'es',
  },
  {
    name: 'English',
    icon: 'en',
    locale: 'en',
  },
  {
    name: 'French',
    icon: 'fr',
    locale: 'fr',
  },
  {
    name: 'Hebrew',
    icon: 'he',
    locale: 'he',
  },
  {
    name: 'Russian',
    icon: 'ru',
    locale: 'ru',
  },
  {
    name: 'Arabic',
    icon: 'ar',
    locale: 'ar',
  },
  {
    name: 'Chinese',
    icon: 'cn',
    locale: 'cn',
  },
  {
    name: 'German',
    icon: 'ge',
    locale: 'ge',
  },
  {
    name: 'Japanese',
    icon: 'ja',
    locale: 'ja',
  },
  {
    name: 'Korean',
    icon: 'ko',
    locale: 'ko',
  },
  {
    name: 'Italian',
    icon: 'it',
    locale: 'it',
  },
  {
    name: 'Hungarian',
    icon: 'hu',
    locale: 'hu',
  },
];
