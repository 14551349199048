/**
 * AppData Module
 */

import Vue from 'vue';
import api from '@/api';
import { i18n } from '@/main';

// getters
const getters = {
  routes: (state) => {
    return state.routes;
  },
};

// actions
const actions = {
  async public(context) {
    try {
      const response = await api.get('config/public');

      initKeys(context, response.data);
    } catch {
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.shared.appLoadDataError'),
      });
    }
  },
  async private(context) {
    try {
      const response = await api.get('config/private');

      initKeys(context, response.data);
    } catch (error) {
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.shared.appLoadDataError'),
      });
    }
  },
  async client(context) {
    try {
      const response = await api.get('config/client');

      initKeys(context, response.data);
    } catch {
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.shared.appLoadDataError'),
      });
    }
  },
};

// mutations
const mutations = {
  setData(state, data) {
    state[data.index] = data.value;
  },
  init(state) {
    Object.assign(state, initialize());
  },
};

// Helpers
function initKeys(context, data, ignore = []) {
  // remove not data related keys
  ignore.push('success', 'validate');

  for (let key in data) {
    if (ignore.includes(key)) continue;

    context.commit('setData', {
      index: key,
      value: data[key],
    });
  }
}

function initialize() {
  return {
    employmentStatus: [],
    housingStatus: [],
    maritalStatus: [],
    professions: [],
    countries: [],
    provinces: [],
    propertyUses: [],
    interestRateRegimes: [],
    documents: [],
    usersStatus: [],
    loanLabels: [],
    menu: null,
    routes: [],
    propertyTitles: [],
    url: {},
    contactData: {},
  };
}

export default {
  state: initialize,
  getters,
  actions,
  mutations,
  namespaced: true,
};
