/**
 * AppData Module
 */

import Vue from 'vue';
import api from '@/api';
import Nprogress from 'nprogress';
import router from '@/router';
import { i18n } from '@/main';

const getters = {
  user: (state) => {
    return typeof state.user === 'string' ? JSON.parse(state.user) : null;
  },
  role: (state) => {
    if (typeof state.user === 'string') {
      const user = JSON.parse(state.user);
      return user.role;
    }

    return null;
  },
  token: (state) => {
    return state.token;
  },
  wallet: (state) => {
    if (typeof state.user === 'string') {
      return JSON.parse(state.user)['wallet'];
    }

    return state.user.wallet;
  },
};

const actions = {
  async signIn(context, payload) {
    Nprogress.start();

    try {
      const response = await api.post(`auth/${payload.action}`, payload.data);

      if (response.data.success) {
        context.commit('login', response.data);

        // Load user restricted app config data
        await context.dispatch('appdata/private', null, { root: true });

        const path = payload.data.redirect ? payload.data.redirect : '/leads';

        router.push(path);
      } else {
        Vue.notify({
          group: 'api',
          type: 'error',
          text: i18n.t('message.session.loginError' + response.data.error.code),
        });
      }
    } catch (error) {
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.session.loginRequestError'),
      });
    } finally {
      Nprogress.done();
    }
  },
  async signUp(context, payload) {
    Nprogress.start();

    try {
      const response = await api.post('auth/signup', payload);

      if (response.data.success) {
        context.commit('login', response.data);

        // Load user restricted app config data
        await context.dispatch('appdata/private', null, { root: true });

        router.push('/leads').catch(() => {});

        return;
      }

      return Promise.reject(i18n.t('message.session.signupRequestError'));
    } catch (error) {
      const message =
        error.response.status === 422
          ? signUpErrorMessage(error.response.data.errors)
          : 'message.session.signupRequestError';
      return Promise.reject(i18n.t(message));
    } finally {
      Nprogress.done();
    }
  },
  async logout(context, path) {
    Nprogress.start();

    try {
      await api.get('auth/logout');

      context.commit('logout');

      clearStoreData(context);

      location.assign(path);
    } catch (error) {
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.session.logoutRequestError'),
      });
    } finally {
      Nprogress.done();
    }
  },
  async expired(context) {
    Nprogress.start();

    try {
      context.commit('logout');
      clearStoreData(context);
      location.assign('/login');
    } catch (error) {
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.session.logoutRequestError'),
      });
    } finally {
      Nprogress.done();
    }
  },
};

const mutations = {
  login(state, data) {
    // stringify to save object as string on local storage
    const user = JSON.stringify(data.user);

    fillStateAndLocalStorage(state, user, data);
  },
  logout(state) {
    Object.keys(state).forEach((k) => (state[k] = null));
    clearLocalStorage();
  },
  setWallet(state, walletAmount) {
    let user =
      typeof state.user === 'string'
        ? JSON.parse(state.user)
        : state.user.wallet;

    user.wallet = walletAmount;

    state.user = JSON.stringify(user);
    localStorage.setItem('user', state.user);
  },
};

const initialize = () => {
  return {
    user: localStorage.getItem('user'),
    token: localStorage.getItem('token'),
    version: localStorage.getItem('version'),
  };
};

const fillStateAndLocalStorage = (state, user, data) => {
  state.user = user;
  state.token = data.token;
  state.version = data.version;

  localStorage.setItem('user', user);
  localStorage.setItem('token', data.token);
  localStorage.setItem('version', data.version);
};

const clearLocalStorage = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('version');
};

const clearStoreData = (context) => {
  console.log(context);
  // Clear all store data
  // commons.forEach((key) => context.commit(`${key}/init`, null, { root: true }));
  // employee.forEach((key) =>
  //   context.commit(`${key}/init`, null, { root: true })
  // );
  // operation.forEach((key) =>
  //   context.commit(`operation/${key}/init`, null, { root: true })
  // );
};

const signUpErrorMessage = (errors) => {
  let message = 'message.session.signupRequestError';

  const uniques = [
    { field: 'email', message: 'message.session.signupErrorEmailExists' },
    {
      field: 'identification',
      message: 'message.session.signupErrorCompanyIDExists',
    },
  ];

  loop: for (let i = 0; i < uniques.length; i++) {
    if (uniques[i].field in errors) {
      for (let key in errors[uniques[i].field]) {
        if (errors[uniques[i].field][key].includes('has already been taken')) {
          message = uniques[i].message;
          break loop;
        }
      }
    }
  }

  return message;
};

export default {
  state: initialize,
  getters,
  actions,
  mutations,
  namespaced: true,
};
