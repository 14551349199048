export default {
  path: '/secured',
  component: () => import('@/layouts/SecuredLayout'),
  redirect: '/',
  children: [
    {
      path: '/secured/client-area/:token',
      component: () => import('@/views/secured/ClientArea'),
      meta: {
        auth: false,
        title: 'message.secured.clientAreaTitle',
        breadcrumb: null,
      },
    },
  ],
};
