import { languages } from './data';

const state = {
  languages,
  selectedLocale: languages[0],
};

// getters
const getters = {
  selectedLocale: (state) => {
    return state.selectedLocale;
  },
  languages: (state) => {
    return state.languages;
  },
};

// actions
const actions = {
  changeLanguage(context, payload) {
    context.commit('changeLanguageHandler', payload);
  },
};

// mutations
const mutations = {
  changeLanguageHandler(state, language) {
    state.selectedLocale = language;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
