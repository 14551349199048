/**
 * Version Module
 */

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {
  setRefreshApp(state, value) {
    state.refreshApp = value;
  },
};

// helpers
function initialize() {
  return {
    refreshApp: false,
  };
}

export default {
  state: initialize,
  getters,
  actions,
  mutations,
  namespaced: true,
};
