/**
 * Loan Module
 */
import Vue from 'vue';
import api from '@/api';
import Nprogress from 'nprogress';
import { i18n } from '@/main';

// getters
const getters = {};

// actions
const actions = {
  async pickLoan(context) {
    if (context.state.updating) {
      Vue.notify({
        group: 'api',
        type: 'warning',
        text: i18n.t('message.shared.ongoingRequest'),
      });
      return Promise.reject();
    }

    context.commit('setUpdating', true);
    Nprogress.start();

    try {
      const response = await api.post(`loan/${context.state.data.id}/pick`);

      if (response.data.success) {
        context.commit('setLoanUser', response.data.user);
        context.commit('auth/setWallet', response.data.wallet, { root: true });
        context.commit(
          'operation/applicants/loaded',
          response.data.applicants,
          {
            root: true,
          }
        );
        context.commit(
          'operation/applicants/documents/loaded',
          {
            list: response.data.documents,
          },
          { root: true }
        );

        Vue.notify({
          group: 'api',
          type: 'success',
          text: i18n.t('message.loan.pickLoanSuccess'),
        });
      } else {
        Vue.notify({
          group: 'api',
          type: 'error',
          text: response.data.error.code
            ? i18n.t(`message.loan.pickLoanError_${response.data.error.code}`)
            : i18n.t('message.loan.pickLoanError'),
        });
      }
    } catch (error) {
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.loan.pickLoanError'),
      });
    } finally {
      Nprogress.done();
      context.commit('setUpdating', false);
    }
  },
};

// mutations
const mutations = {
  setUpdating(state, value) {
    state.updating = value;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setLoanUser(state, user) {
    state.data['user'] = user;
  },
  loaded(state, { loan, tier }) {
    state.data = loan;
    state.tier = tier;

    state.loading = false;
    state.loaded = true;
  },
  loading(state) {
    let template = initialize();
    template['loading'] = true;
    for (let key in template) state[key] = template[key];
  },
  init(state) {
    Object.assign(state, initialize());
  },
};

// helpers
function initialize() {
  return {
    data: null,
    tier: null,
    extras: [],
    loading: false,
    loaded: false,
    updating: false,
  };
}

export default {
  state: initialize,
  getters,
  actions,
  mutations,
  namespaced: true,
};
