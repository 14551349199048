/**
 * User Module
 */

import Vue from 'vue';
import api from '@/api';
import Nprogress from 'nprogress';
import { i18n } from '@/main';

// getters
const getters = {
  menu: (state) => {
    return state.menu;
  },
};

// actions
const actions = {
  async load(context) {
    // Already on loading process
    if (context.state.loading) return;

    Nprogress.start();
    context.commit('loading');

    try {
      const response = await api.get('user');

      if (response.data.success) {
        context.commit('loaded', response.data);
      }
    } catch (error) {
      context.commit('setLoading', false);
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.shared.loadDataError'),
      });
    } finally {
      Nprogress.done();
    }
  },
  async update(context, payload) {
    if (context.state.updating) {
      Vue.notify({
        group: 'api',
        type: 'warning',
        text: i18n.t('message.shared.ongoingRequest'),
      });
      return Promise.reject();
    }

    context.commit('setUpdating', true);
    Nprogress.start();

    try {
      const response = await api.put('user', payload);

      if (response.data.success) {
        context.commit('setData', response.data.user);

        Vue.notify({
          group: 'api',
          type: 'success',
          text: i18n.t('message.shared.updateSuccess'),
        });
        return Promise.resolve(true);
      }

      return Promise.reject();
    } catch (error) {
      const message =
        error.response.status === 422
          ? updateUserErrorMessage(error.response.data.errors)
          : 'message.shared.updateError';

      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t(message),
      });

      return Promise.reject();
    } finally {
      Nprogress.done();
      context.commit('setUpdating', false);
    }
  },
  async password(context, payload) {
    if (context.state.updating) {
      Vue.notify({
        group: 'api',
        type: 'warning',
        text: i18n.t('message.shared.ongoingRequest'),
      });
      return Promise.reject();
    }

    context.commit('setUpdating', true);
    Nprogress.start();

    try {
      const response = await api.put('user/password', payload);

      if (response.data.success) {
        Vue.notify({
          group: 'api',
          type: 'success',
          text: i18n.t('message.user.passwordSuccess'),
        });
        return Promise.resolve(true);
      }

      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.user.' + response.data.error.code),
      });

      return Promise.reject();
    } catch (error) {
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.shared.updateError'),
      });
      return Promise.reject();
    } finally {
      Nprogress.done();
      context.commit('setUpdating', false);
    }
  },
  async uploadImage(context, payload) {
    if (context.state.updating) {
      Vue.notify({
        group: 'api',
        type: 'warning',
        text: i18n.t('message.shared.ongoingRequest'),
      });
      return Promise.reject();
    }

    context.commit('setUpdating', true);
    Nprogress.start();

    try {
      const response = await api.post(
        `user/data/${payload.id}/upload`,
        payload.value
      );

      if (response.data.success) {
        context.commit('setExtrasById', response.data.data);

        Vue.notify({
          group: 'api',
          type: 'success',
          text: i18n.t('message.shared.updateSuccess'),
        });
        return Promise.resolve(true);
      }

      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.shared.updateError'),
      });

      return Promise.reject();
    } catch (error) {
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.shared.updateError'),
      });

      return Promise.reject();
    } finally {
      Nprogress.done();
      context.commit('setUpdating', false);
    }
  },
  async bulkData(context, payload) {
    if (context.state.updating) {
      Vue.notify({
        group: 'api',
        type: 'warning',
        text: i18n.t('message.shared.ongoingRequest'),
      });
      return Promise.reject();
    }

    context.commit('setUpdating', true);
    Nprogress.start();

    try {
      const response = await api.post('user/data', payload);

      if (response.data.success) {
        payload.texts.forEach((d) => context.commit('setExtrasById', d));

        Vue.notify({
          group: 'api',
          type: 'success',
          text: i18n.t('message.shared.updateSuccess'),
        });
        return Promise.resolve(true);
      }

      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.shared.updateError'),
      });

      return Promise.reject();
    } catch (error) {
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.shared.updateError'),
      });

      return Promise.reject();
    } finally {
      Nprogress.done();
      context.commit('setUpdating', false);
    }
  },
};

// mutations
const mutations = {
  setData(state, value) {
    state.data = value;
  },
  setExtrasById(state, data) {
    var index = state.extras.findIndex((e) => e.id === data.id);

    if (index === -1) {
      state.extras.push(data);
    } else {
      state.extras.splice(index, 1, data);
    }
  },
  setUpdating(state, value) {
    state.updating = value;
  },
  setMenu(state, value) {
    state.menu = value;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  loaded(state, value) {
    state.data = value.user;
    state.extras = value.data;

    state.loading = false;
    state.loaded = true;
  },
  loading(state) {
    let template = initialize();
    template['loading'] = true;
    for (var key in template) state[key] = template[key];
  },
  init(state) {
    Object.assign(state, initialize());
  },
};

// helpers
function initialize() {
  return {
    data: null,
    extras: null,
    // organization: null,
    permissions: null,
    configuration: null,
    loading: false,
    loaded: false,
    updating: false,
    menu: 'form',
  };
}

const updateUserErrorMessage = (errors) => {
  let message = 'message.shared.updateError';

  const uniques = [
    { field: 'email', message: 'message.user.userErrorEmailExists' },
    { field: 'idNumber', message: 'message.user.userErrorIDExists' },
  ];

  loop: for (let i = 0; i < uniques.length; i++) {
    if (uniques[i].field in errors) {
      for (let key in errors[uniques[i].field]) {
        if (errors[uniques[i].field][key].includes('has already been taken')) {
          message = uniques[i].message;
          break loop;
        }
      }
    }
  }

  return message;
};

export default {
  state: initialize,
  getters,
  actions,
  mutations,
  namespaced: true,
};
