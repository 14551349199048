/**
 * Loan Module
 */
import documents from './documents';
import { getObjectBy } from '@/helpers/helpers';

// getters
const getters = {
  holder: (state) => (id) => {
    return getObjectBy(state.holders, 'id', id);
  },
  totalHolders: (state) => {
    return state.holders.length;
  },
  holdersNetIncome: (state) => {
    let total = 0;

    state.holders.forEach((holder) => {
      if (holder.monthlyIncome) {
        total += parseFloat(holder.monthlyIncome);
      }

      if (holder.monthlyOtherIncome) {
        total += parseFloat(holder.monthlyOtherIncome);
      }
    });

    return total;
  },
  holdersCopy: (state) => {
    return JSON.parse(JSON.stringify(state.holders));
  },
  guarantorsCopy: (state) => {
    return JSON.parse(JSON.stringify(state.guarantors));
  },
  nonDebtorsCopy: (state) => {
    return JSON.parse(JSON.stringify(state.nonDebtors));
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  loaded(state, applicants) {
    addApplicants(state, applicants);

    state.loading = false;
    state.loaded = true;
  },
  setHolders(state, value) {
    state.holders = value;
    state.primary = getPrimary(value);
  },
  setUpdating(state, value) {
    state.updating = value;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  loading(state) {
    let template = initialize();
    template['loading'] = true;
    for (let key in template) state[key] = template[key];
  },
  init(state) {
    Object.assign(state, initialize());
  },
};

// helpers
function initialize() {
  return {
    primary: null,
    holders: [],
    guarantors: [],
    nonDebtors: [],
    loading: false,
    loaded: false,
    updating: false,
  };
}

function getPrimary(holders) {
  for (let key in holders) {
    if (holders[key].loanApplicant.primary == 1) {
      return holders[key];
    }
  }
}

function addApplicants(state, applicants) {
  state.primary = applicants.find(a => a.primary);
  state.holders = applicants;
}

export default {
  state: initialize,
  getters,
  actions,
  mutations,
  namespaced: true,
  modules: {
    documents,
  },
};
