export default {
  resume: 'Perfil de usuario',
  profile: 'Perfil',
  password: 'Contraseña',
  loadedError: 'Error al recuperar los datos del usuario',
  admin: 'Administrador',
  name: 'Nombre y Apellidos',
  email: 'Email',
  phone: 'Teléfono',
  created: 'Creado',
  country: 'País',
  province: 'Provincia/Región',
  city: 'Ciudad',
  address: 'Dirección',
  idType: 'Tipo de identificación',
  idNumber: 'Número de identificación',
  companyName: 'Nombre de la empresa',
  postalCode: 'Código postal',
  oldPassword: 'Contraseña actual',
  newPassword: 'Contraseña nueva',
  confirmPassword: 'Confirmar contraseña',
  updatePassword: 'Actualizar contraseña',
  passwordMissmatchError: 'La contraseña actual no es correcta',
  passwordUpdateError: 'Error al actualizar la contraseña',
  passwordSuccess: 'Contraseña actualizada',
  userErrorEmailExists: 'Ya existe un usuario con el email indicado',
  userErrorIDExists:
    'Ya existe un usuario con el número de identificación indicado',
  userMenuLabel: 'Usuario',
  userMenuItem: 'General',
  userProfessionalMenuItem: 'Datos profesionales',
  userProfileMenuItemTitle: 'Perfil',
  userProfileMenuItemDescription: 'Modifica tus datos de usuario.',
  userDataMenuItemTitle: 'Datos profesionales',
  userDataMenuItemDescription: 'Añade tu información básica como profesional.',
  userDataProfileImageTitle: 'Foto de perfil',
  userDataProfileImageDescription:
    'Añade la foto de perfil que será visible para tus clientes.',
  userPasswordMenuItemTitle: 'Contraseña',
  userPasswordMenuItemDescription: 'Actualiza tu contraseña.',
  userDataOrganization: 'Nombre organización',
  userDataWebsite: 'Sitio web',
  userDataLicenseNumber: 'Número de licencia',
  profileImage: 'Imagen de perfil',
  dropProfileImage: 'Arrastra la imagen o haz clic para agregarla',
  currentProfileImage: 'Imagen actual',
};
