export default {
  title: 'Expediente',
  resumeHeader: 'Expediente',
  operationData: 'DATOS OPERACIÓN',
  propertyUse: 'Uso vivienda',
  propertyValue: 'Importe Compra',
  deposit: 'Aportación',
  maturity: 'Plazo',
  ltv: 'LTV',
  propertyType: 'Tipo vivienda',
  mortgageValue: 'Importe Hipoteca',
  interest: 'Interés estimado',
  costs: 'Costes',
  interestType: 'Tipo interés',
  financingRatio: 'Coef financiación',
  propertyData: 'DATOS INMUEBLE',
  address: 'Dirección',
  province: 'Provincia',
  holdersData: 'DATOS TITULAR(ES)',
  guarantorsData: 'DATOS AVALISTA(S)',
  personalData: 'DATOS PERSONALES',
  firstApplicant: '1er TITULAR',
  secondApplicant: '2do TITULAR',
  firstGuarantor: 'AVALISTA No. 1',
  secondGuarantor: 'AVALISTA No. 2',
  name: 'Nombre',
  lastname: 'Apellidos',
  phone: 'Teléfono',
  email: 'Email',
  idType: 'NIF / NIE / Pasaporte',
  currentAddress: 'Domicilio',
  birthdate: 'Fecha nacimiento',
  age: 'Edad',
  maritalStatus: 'Estado civil',
  currentHomeStatus: 'Vivienda actual',
  dependants: 'Personas a su cargo',
  professionalData: 'DATOS PROFESIONALES',
  employmentType: 'Tipo de contrato',
  timeAtCurrentJob: 'Antigüedad empleo actual',
  profession: 'Profesión / Cargo',
  companyName: 'Nombre empresa',
  timeInCareer: 'Vida laboral',
  financialData: 'DATOS ECONÓMICOS',
  declaredMonthlyIncome: 'Ingresos mensuales S/Renta',
  monthlyDebtRemoving: 'Gastos que cesan con la hipoteca',
  netMonthlyIncome: 'Ingreso neto mensual',
  familyIncome: 'Ingresos ud. familiar',
  debts: 'Cuota préstamos',
  extraIncome: 'Otros ingresos',
  firstYearMortgage: 'Cuota Hipoteca 1er año',
  indebtedness: 'Endeudamiento',
  resume: 'RESUMEN',
  newProperty: 'Nueva',
  secondHandProperty: 'Segunda mano',
  titleb2b: 'FICHA EXPEDIENTE - PROHIPOTECAS',
  titleb2c: 'FICHA EXPEDIENTE - FINTECA',
  addComments: 'Agregar observaciones',
  comments: 'Observaciones',
  serverTitle: 'En el servidor',
  serverCurrentFile: 'Archivo actual',
  availability: 'Disponibilidad',
  date: 'Fecha',
  analyst: 'Analista',
  manager: 'Intermediario/a',
  customize: 'Añadir observaciones',
  nationality: 'Nacionalidad',
  downloadPdf: 'Descargar como PDF',
  downloadXlsx: 'Descargar como XLSX',
  guarantors: 'Avalistas',
  guarantorType: 'Tipo de aval',
  guarantorAndNonDebtorTypeName: 'Nominal y con garantía',
  guarantorTypeName: 'Nominal',
  nonDebtorTypeName: 'Con garantía',
  holdersFinancings: 'PRÉSTAMOS PENDIENTES TITULAR(ES)',
  guarantorsFinancings: 'PRÉSTAMOS PENDIENTES AVALISTA(S)',
  financings: 'PRÉSTAMOS PENDIENTES',
  financing: 'PRÉSTAMO PENDIENTE',
  collaterals: 'GARANTÍA HIPOTECARIA',
  burdens: 'CARGAS ASOCIADAS',
  holder: 'Titular',
  organization: 'Entidad',
  type: 'Tipo',
  dates: 'Inicio - Fin',
  startAt: 'Inicio',
  endAt: 'Fin',
  amount: 'Inicial',
  pendingAmount: 'Pendiente',
  payment: 'Cuota',
  holdersAmount: 'Titulares',
  cancelling: 'Cancela',
  holderRelation: 'Parentesco',
  ownedPercent: 'Porcentaje',
  collateralValue: 'Valor propiedad',
  collateralAddress: 'Dirección',
  burdenAmount: 'Importe inicial',
  commentsPlaceholder:
    'Indica aquí la información adicional que quieras incluir en el apartado de resumen del expediente',
  filename: 'Expediente',
};
