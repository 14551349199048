<template>
  <o-modal
    :active.sync="showDialog"
    :width="640"
    contentClass="modal-card has-text-left mobile-modal"
    :canCancel="false"
    scroll="clip"
  >
    <section class="modal-card-body content">
      <div class="has-text-centered my-4">
        <img class="probroker-logo" :src="appLogo" />
      </div>
      <p class="has-text-centered">{{ $t('message.shared.newVersion') }}</p>
      <p
        class="
          has-text-centered
          is-uppercase is-size-4
          has-text-weigth-semibold
        "
      >
        {{ $t('message.shared.updating') }}
      </p>
      <div class="loading-box">
        <o-loading :full-page="false" :active.sync="showDialog"></o-loading>
      </div>
    </section>
  </o-modal>
</template>

<script>
import AppConfig from '@/constants/AppConfig';

export default {
  data() {
    return {
      appLogo: AppConfig.appLogo,
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit('cancel');
      },
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (value) {
          setTimeout(() => window.location.reload(true), 3000);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-box {
  margin-top: 40px;
  padding: 60px 0;
}

.probroker-logo {
  max-width: 200px;
}
</style>