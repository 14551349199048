export default {
  title: 'Área privada de cliente',
  holders: 'Titular(es)',
  invalidToken: 'Token no válido',
  expiredToken:
    'El token ha expirado. Por favor contacta con tu gestor para obtener uno nuevo.',
  documentUpload: 'Documentación para estudio hipotecario',
  documentUploadSubtitle: 'Adjunta la documentación para cada titular:',
  documents: 'Documentación',
  applicants: 'Titulares',
  loan: 'Hipoteca',
  addApplicant: 'Añadir segundo titular',
  uploadDocuments:
    'Por favor, adjunta para cada titular o avalista los documentos solicitados por tu gestor para poder empezar el proceso de búsqueda de tu mejor hipoteca.',
  extraDocuments:
    'Durante el proceso, te pediremos más documentación, así que toda la que puedas avanzarnos, ¡mejor!',
  helpDocuments:
    'Si tienes dudas a la hora de recopilar y enviar la documentación, no dudes en contactar con nosotros.',
  documentErrorList: 'Error al subir los siguientes documentos',
  uploadedDocuments: 'Documentos subidos',
  login: 'Inicio de sesión',
  loginInstructions: 'Indique email y contraseña para acceder',
  loginLenderInstructions: 'Indique usuario y contraseña para acceder',
  redirectValidationError: 'Página de destino no válida',
  loanDocuments: 'Documentación hipoteca',
  loadFileError:
    'Error al recuperar el expediente y la documentación de la hipoteca',
  downloadFile: 'Descargar expediente',
  fileDefaultName: 'expediente-operacion.pdf',
  clientAreaTitle: 'Área privada de cliente',
  downloadAll: 'Descargar todos (.zip)',
  downloadFileInfo:
    'Puedes descargarte el expediente de la operación. Además, encontrarás los documentos de cada interviniente. Puedes descargarlos todos o de uno en uno.',

  // Documents
  documentsTitle: 'Documentación para estudio hipotecario',
  addDocumentsDescription:
    'Adjunta la documentación requerida para cada titular:',
  rgpdDownload: 'Puedes descargar el documento en ',
  rgpdDownloadLink: 'este enlace',
  completed: 'Completado',
  upload: 'Subir',
  noFiles: 'Ningún archivo subido',
  files: 'Archivos subidos:',
  uploadedFilesSuffix: 'archivo(s) subido(s)',
  otherFilesSuffix: 'archivo(s)',
  othersDocumentsTitle: 'Otros',
  othersDocumentsDescription:
    'Cualquier otro tipo de documento, como vida laboral, extractos bancarios, etc.',

  // Additional Documents
  additionalDocumentsTitle: 'Documentación adicional',
  addAdditionalDocumentsDescription:
    'En caso de que fuera necesario ajunta la documentación adicional requerida:',
  additionalDocumentsFormTitle: 'Documentos adicionales',
  additionalDocumentsFormDescription:
    'Selecciona el tipo de documento que deseas subir y adjunta el archivo.',
  documentSelectType: 'Tipo de archivo',
  documentSizeError: 'Error al subir el archivo. Tamaño máximo: 20MB.',
  documentUploaded: 'Archivo(s) guardado correctamente',
  documentUploadError: 'Ocurrió un error al subir el archivo',
  attachDocumentation: 'Adjuntar documentación',

  documentsDescription: 'Documentación para estudio hipotecario',

  // Loan resumes
  resume_loan: 'HIPOTECA',
  resume_loanType: 'Tipo de hipoteca',
  resume_propertyValue: 'Valor de la propiedad',
  resume_requiredFunds: 'Gastos aproximados',
  resume_depositAmount: 'Aportación inicial',
  resume_loanAmount: 'Importe de la hipoteca',
  resume_maturity: 'Plazo de la hipoteca',
  resume_interestRateRegimes: 'Tipo de interés',
  resume_ltv: 'Porcentaje de hipoteca',
  resume_property: 'PROPIEDAD',
  resume_propertyUse: 'Propiedad destinada a',
  resume_province: 'Provincia',
  resume_city: 'Ciudad',
  resume_postalCode: 'Código postal',
  resume_address: 'Dirección',
  resume_applicants: 'TITULAR(ES)',
  resume_primary: 'PRIMER TITULAR',
  resume_secondary: 'SEGUNDO TITULAR',
  resume_name: 'Nombre',
  resume_email: 'Email',
  resume_idNumber: 'DNI / NIE / Pasaporte',

  // Broker resume
  brokerResumeTitle: 'Tu broker',
  callBroker: 'Llamar',
  licenseNumber: 'Licencia'
};
