/**
 * Helpers Functions
 */
import moment from 'moment';
import xlsx from 'xlsx';
import { i18n } from '@/main';

// Get Date
export function getTheDate(timestamp, format) {
  let time = timestamp * 1000;
  let formatDate = format ? format : 'MM-DD-YYYY';
  return moment(time).format(formatDate);
}

// Convert Date To Timestamp
export function convertDateToTimeStamp(date, format) {
  let formatDate = format ? format : 'YYYY-MM-DD';
  return moment(date, formatDate).unix();
}

export function dateFormatter(date, format = 'YYYY-MM-DD') {
  return moment(date).format(format);
}

// Allows to change format to 'YYYY-MM-DD HH:mm:ss' {date}
export function formatDate(date, format = 'DD/MM/YYYY HH:mm:ss') {
  let formatted = moment(date, 'YYYY-MM-DD HH:mm:ss');
  return formatted.format(format);
}

// Transcurred {unit} from {date} until today
export function timeSince(
  date,
  unit = 'days',
  startOf = 'day',
  format = 'YYYY-MM-DD'
) {
  return moment()
    .startOf(startOf)
    .diff(moment(date, format).startOf(startOf), unit);
}

// Transcurred {unit} from {init} to {end}
export function timeBetween(init, end, unit = 'days', startOf = 'day') {
  const since = moment(init).startOf(startOf);
  const until = moment(end).startOf(startOf);

  return until.diff(since, unit);
}

// Text Truncate
export function textTruncate(str, length = 100, ending = '...') {
  if (!str) return null;

  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

// Replaces {mapbObj} values in {text}
export function replaceInText(text, mapObj) {
  let regex = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return text.replace(regex, function (matched) {
    return mapObj[matched.toLowerCase()];
  });
}

// Function to convert hex to rgba
export function hexToRgbA(hex, alpha) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      ',' +
      alpha +
      ')'
    );
  }
  throw new Error('Bad Hex');
}

// Function to return currenr app layout
export function getCurrentAppLayout(router) {
  let location = router.history.current.fullPath;
  let path = location.split('/');
  return path[1];
}

// Search {match} by {key} in object. Return {value}
export function getValueBy(
  items,
  match,
  config = { key: 'id', value: 'description' }
) {
  let result = items.find((i) => i[config.key] == match);
  return result ? result[config.value] : null;
}

// Search {match} by {key} in object. Return object
export function getObjectBy(items, key, match) {
  let result = items.find((i) => i[key] == match);
  return result ? result : null;
}

// Returns filters that have a valid value in {filters}
export function getActiveFilters(copy, datefix = []) {
  let active = [];
  const filters = cleanCopy(copy);

  datefix.forEach((key) => {
    if (filters[key].value) {
      filters[key].value =
        key === 'end'
          ? moment(filters[key].value).add(1, 'days').format('YYYY-MM-DD')
          : moment(filters[key].value).format('YYYY-MM-DD');
    }
  });

  // Si no se filtra por lost, se añade el filtro de lost a false
  for (let key in filters) {
    if (filters[key].value) {
      if (typeof filters[key].value === 'object') {
        if (filters[key].value.length === 0) continue;
      }
      if (key === 'lost') {
        if (
          filters.multievent.conditions.some(
            (c) => c.column === 'lost' && !!c.value.length
          )
        ) {
          continue;
        }
      }
      active.push(filters[key]);
    }
  }

  return active;
}

// copy object
export function cleanCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

// validate user is logged in
export function isLogged() {
  return localStorage.getItem('user') != null;
}

// Replaces {item} in {group}. Match by {index}
export function updateList(item, group, index = 'id') {
  let copy = JSON.parse(JSON.stringify(group));
  let match = false;

  for (let key in copy) {
    if (copy[key][index] === item[index]) {
      copy[key] = item;
      match = true;
      break;
    }
  }

  // New record
  if (!match) copy.unshift(item);
  return copy;
}

// Replaces {item} in {group} and keeps all the object keys in {ignore}. Match by {index}
export function updateIgnoreList(item, group, ignore, index = 'id') {
  let copy = JSON.parse(JSON.stringify(group));
  let match = false;

  for (let key in copy) {
    if (copy[key][index] == item[index]) {
      copy[key] = item;
      match = true;

      // copy ignored keys
      ignore.forEach((element) => {
        if (element in group[key]) {
          copy[key][element] = JSON.parse(JSON.stringify(group[key][element]));
        }
      });
      break;
    }
  }

  // New record
  if (!match) copy.unshift(item);
  return copy;
}

// Removes {item} in {group}. Match by {index}
export function deleteList(item, group, index = 'id') {
  let copy = JSON.parse(JSON.stringify(group));
  let match = null;

  for (let key in copy) {
    if (copy[key][index] == item) {
      match = key;
      break;
    }
  }

  copy.splice(match, 1);
  return copy;
}

//
export function percent(total, fraction) {
  return total > 0 ? Math.round((100 * fraction) / total) : 0;
}

// mortgage quota
export function calcQuota(years, rate, price, deposit) {
  const months = years * 12;
  const interest = rate / (12 * 100);

  const numerator = (price - deposit) * interest;
  const denominator = 1 - Math.pow(1 + interest, months * -1);

  return Math.round(numerator / denominator);
}

// mortgage dti
export function calcDTI(quota, debts, incomes) {
  return 100 * ((+quota + +debts) / +incomes);
}

// mortgage ltv
export function calcLTV(price, deposit, costs, round = true) {
  const ltv = 100 * ((+price - +deposit + +costs) / +price);

  if (round) {
    return Math.round(ltv);
  }

  return ltv;
}

// deposit to pass ltv condition
export function calcLTVDeposit(ltv, price, costs) {
  return costs + price - (ltv * price) / 100;
}

// deposit to pass dti condition
export function calcDTIDeposit(dti, rate, data, costs) {
  const interest = rate / (12 * 100);
  return (
    data.price +
    costs -
    (((dti * data.incomes) / 100 - data.debts) *
      (1 - Math.pow(1 + interest, 12 * data.maturity * -1))) /
      interest
  );
}

// incomes to pass dti condition
export function calcDTIIncome(dti, quota, debts) {
  return 100 * ((quota + debts) / dti);
}

// property value to pass ltv condition
export function calcLTVProperty(ltv, deposit, fundsRate) {
  return deposit / (fundsRate + 1 - ltv / 100);
}

// property value to pass dti condition
export function calcDTIProperty(
  dti,
  incomes,
  debts,
  rate,
  deposit,
  years,
  fundsRate
) {
  const months = years * 12;
  const interest = rate / (12 * 100);
  const denominator = 1 - Math.pow(1 + interest, months * -1);
  const xQuota = (dti / 100) * incomes - debts;

  return (deposit + (xQuota * denominator) / interest) / (1 + fundsRate);
}

// add each element of an array
export function sumArray(elements) {
  return elements.reduce(function (a, b) {
    return a + b;
  }, 0);
}

// next multiple of
export function roundToMultiple(value, multiple) {
  return Math.ceil(value / multiple) * multiple;
}

//
export function formatCurrency(number) {
  if (typeof number === 'string') {
    number = parseFloat(number);
  }

  return number || number == 0
    ? number.toLocaleString(i18n.t('message.config.digitLocale'), {
        style: 'decimal',
      }) + '€'
    : '—';
}

// Filter array and delete duplicate values
export function uniqueFilter(value, index, self) {
  return self.indexOf(value) === index;
}

// Color class according to the range in which {value} is. {type categorized}
export function colors(type, value) {
  const ranges = {
    indebtedness: {
      red: {
        lower: 41,
        upper: 100,
      },
      orange: {
        lower: 31,
        upper: 40,
      },
      green: {
        lower: 0,
        upper: 30,
      },
    },
    financed: {
      red: {
        lower: 95,
        upper: 1000,
      },
      orange: {
        lower: 85,
        upper: 94,
      },
      green: {
        lower: 0,
        upper: 84,
      },
    },
  };

  let color = '';

  for (let key in ranges[type]) {
    if (
      ranges[type][key].lower <= +value &&
      ranges[type][key].upper >= +value
    ) {
      color = key;
      break;
    }
  }

  return color;
}

// First letter of each word in {text}
export function initials(text) {
  if (text) {
    let match = text.match(/\b(\w)/g);
    return match.join('');
  }

  return '—';
}

//
export function formatApplicantName(applicant) {
  let name = '—';

  if (applicant.firstname || applicant.lastname) {
    let names = [];

    if (applicant.firstname) names.push(applicant.firstname);
    if (applicant.lastname) names.push(applicant.lastname);

    name = names.join(' ');
  } else {
    if (applicant.email) {
      name = applicant.email;
    }
  }

  return name.trim();
}

//
export function formatPhone(phone) {
  if (phone) {
    if (phone.startsWith('34')) phone = phone.substring(2);
    else if (phone.startsWith('+34')) phone = phone.substring(3);

    phone = phone.match(/.{1,3}/g);
    return phone.join(' ');
  }

  return null;
}

// When input isn't empty, prepend phone area code
export function setAreaCode(event) {
  if (!event.target.value) event.target.value = 34;
}

// Returns labels in sequential order in the pipeline or a stage
export function buildSequence(config, first) {
  let sequence = [];
  let current = first;

  while (current) {
    sequence.push(current);
    current = config[current].next;

    if (!current) {
      break;
    }
  }

  return sequence;
}

// Download .csv file
export function downloadCSV(csv, filename = 'probroker.csv') {
  let anchor = document.createElement('a');
  anchor.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
  anchor.target = '_blank';
  anchor.download = filename;
  anchor.click();
}

// Download file (blob)
export function downloadBlob(name, data) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
}

export function downloadXLSX(data, filename = 'EXPEDIENTE.xlsx') {
  data = xlsx.utils.json_to_sheet(data);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, data, 'data');
  xlsx.writeFile(wb, filename);
}

export function dni_regex() {
  return /^(\d{8})([A-Z])$/;
}

export function cif_regex() {
  return /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
}

export function nie_regex() {
  return /^[XYZ]\d{7,8}[A-Z]$/;
}

export function idType(str) {
  if (str.match(dni_regex())) {
    return 'dni';
  }

  if (str.match(cif_regex())) {
    return 'cif';
  }

  if (str.match(nie_regex())) {
    return 'nie';
  }
}

export function validDNI(dni) {
  let dni_letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
  let letter = dni_letters.charAt(parseInt(dni, 10) % 23);
  return letter === dni.charAt(8);
}

export function validNIE(nie) {
  // Change the initial letter for the corresponding number and validate as DNI
  let nie_prefix = nie.charAt(0);

  switch (nie_prefix) {
    case 'X':
      nie_prefix = 0;
      break;
    case 'Y':
      nie_prefix = 1;
      break;
    case 'Z':
      nie_prefix = 2;
      break;
  }

  return validDNI(nie_prefix + nie.substr(1));
}

export function validCIF(cif) {
  if (!cif || cif.length !== 9) {
    return false;
  }

  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
  const digits = cif.substr(1, cif.length - 2);
  const letter = cif.substr(0, 1);
  const control = cif.substr(cif.length - 1);
  let sum = 0;
  let i;
  let digit;

  if (!letter.match(/[A-Z]/)) {
    return false;
  }

  for (i = 0; i < digits.length; ++i) {
    digit = parseInt(digits[i]);

    if (isNaN(digit)) {
      return false;
    }

    if (i % 2 === 0) {
      digit *= 2;
      if (digit > 9) {
        digit = parseInt(digit / 10) + (digit % 10);
      }

      sum += digit;
    } else {
      sum += digit;
    }
  }

  sum %= 10;
  if (sum !== 0) {
    digit = 10 - sum;
  } else {
    digit = sum;
  }

  if (letter.match(/[ABEH]/)) {
    return String(digit) === control;
  }
  if (letter.match(/[NPQRSW]/)) {
    return letters[digit] === control;
  }

  return String(digit) === control || letters[digit] === control;
}

export function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export function formatAddress(property, provinces, countries) {
  const address = [];

  if (property.address) {
    address.push(property.address);
  }
  if (property.postalCode) {
    address.push(property.postalCode);
  }
  if (property.city) {
    address.push(property.city);
  }
  if (property.province) {
    address.push(
      getValueBy(provinces, property.province, {
        key: 'id',
        value: 'name',
      })
    );
  }
  if (property.country) {
    address.push(
      getValueBy(countries, property.country, {
        key: 'id',
        value: 'name',
      })
    );
  }

  return address.join(', ');
}

export function formatBasicAddress(property, provinces, countries) {
  const address = [];

  if (property.city && property.city.trim()) {
    address.push(property.city.trim());
  }

  if (property.province) {
    address.push(
      getValueBy(provinces, property.province, {
        key: 'id',
        value: 'name',
      })
    );
  }
  
  if (property.country) {
    address.push(
      getValueBy(countries, property.country, {
        key: 'id',
        value: 'name',
      })
    );
  }

  return address.join(', ');
}

export function simulatorTaxesParser_old(data) {
  const operation = cleanCopy(data);

  const rename = {
    age: 'ages',
    monthlyDebt: 'debts',
    monthlyIncome: 'incomes',
    province: 'propertyProvince',
    employment: 'jobCondition',
  };

  for (var key in rename) {
    operation[rename[key]] = data[key];
    delete operation[key];
  }

  // should be array
  ['incomes', 'debts', 'ages'].forEach((key) => {
    operation[key] = [operation[key]];
  });

  return operation;
}

export function simulatorTaxesParser(data, primary, secondary) {
  const operation = cleanCopy(data);

  const rename = {
    province: 'propertyProvince',
    maturity: 'loanTerm',
  };

  const applicants = {
    age: 'ages',
    monthlyDebt: 'debts',
    monthlyIncome: 'incomes',
    employment: 'jobCondition',
  };

  for (const key in rename) {
    operation[rename[key]] = data[key];
    delete operation[key];
  }

  for (const key in applicants) {
    operation[applicants[key]] = [primary[key]];
  }

  if (secondary) {
    for (const key in applicants) {
      operation[applicants[key]].push(secondary[key]);
    }
  }

  return operation;
}

export function sortAlphabetically(data, key) {
  if (key) {
    return data.sort((a, b) => a[key].localeCompare(b[key]));
  }

  return data.sort((a, b) => a.localeCompare(b));
}

export function sortByAscending(data, key) {
  if (key) {
    return data.sort((a, b) => a[key] - b[key]);
  }

  return data.sort((a, b) => a - b);
}

export function initializePendo(pendo) {
  const userData = localStorage.getItem('user');

  if (userData && pendo) {
    const user = JSON.parse(userData);
    if (user.id) {
      pendo.initialize({
        visitor: {
          id: `${user.id}-${process.env.NODE_ENV}`,
          email: user.email,
          full_name: user.name,
          role: user.role,
        },
        account: {
          // id: 'ACCOUNT-UNIQUE-ID',
        },
      });
    }
  }
}

export function clearPendo(pendo) {
  pendo.clearSession();
}

export function normalizeText(text) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
