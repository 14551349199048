import esValidationMessages from 'vee-validate/dist/locale/es';

// Add custom rules validation messages
esValidationMessages.messages['password'] =
  'La contraseña debe tener un mínimo de 6 caracteres y al menos una letra y un número';
esValidationMessages.messages['spanishId'] =
  'Número de identificación no válido';
esValidationMessages.messages['generalId'] =
  'Número de identificación no válido';
esValidationMessages.messages['phone'] =
  'El formato del teléfono es incorrecto';
esValidationMessages.messages['biggerThanDeposit'] =
  'El valor de la vivienda debe ser mayor al de la aportación';
esValidationMessages.messages['biggerThanPendingAmount'] =
  'El valor de la cantidad total debe debe ser mayor al de la cantidad pendiente';
esValidationMessages.messages['biggerThanFunds'] =
  'La aportación debe ser mayor al valor de los gastos';
esValidationMessages.messages['phoneWithPrefix'] =
  'El formato del teléfono es incorrecto';
  esValidationMessages.messages['url'] =
    'El formato de la url es incorrecto (recuerda que debe empezar por http:// o https://';

export default esValidationMessages;
