export default [
  // SESSION
  {
    path: '/login',
    component: () => import('@/views/session/Login'),
    name: 'login',
    meta: {
      auth: false,
      title: 'message.session.login',
      breadcrumb: null,
      isLogin: true,
    },
  },
  {
    path: '/session-expired',
    component: () => import('@/views/session/Expired'),
    name: 'expired',
    meta: {
      auth: false,
      title: 'message.session.expired',
      breadcrumb: null,
    },
  },
  {
    path: '/logout',
    component: () => import('@/views/session/Logout'),
    name: 'logout',
    meta: {
      auth: false,
      title: 'message.session.logout',
      breadcrumb: null,
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/session/SignUp'),
    meta: {
      auth: false,
      title: 'message.session.signup',
      breadcrumb: null,
      isLogin: true,
    },
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/session/ForgotPassword'),
    meta: {
      auth: false,
      title: 'message.session.forgotPasswordTitle',
      breadcrumb: null,
    },
  },
  {
    path: '/change-password/:token',
    component: () => import('@/views/session/ChangePassword'),
    name: 'change-password',
    meta: {
      auth: false,
      title: 'message.session.changePasswordTitle',
      breadcrumb: null,
    },
  },
  {
    path: '/reset-password/:token',
    component: () => import('@/views/session/ChangePassword'),
    name: 'reset-password',
    meta: {
      auth: false,
      title: 'message.session.resetPasswordTitle',
      breadcrumb: null,
    },
  },
  {
    path: '/contact',
    component: () => import('@/views/session/Contact'),
    name: 'contact',
    meta: {
      auth: false,
      title: 'message.session.contactTitle',
      breadcrumb: null,
    },
  },
  // ERRORS
  {
    path: '/not-found',
    component: () => import('@/views/errors/NotFound'),
    name: 'not-found',
    meta: {
      auth: false,
      title: 'message.errors.notFoundTitle',
      breadcrumb: null,
    },
  },
  {
    path: '/error',
    component: () => import('@/views/errors/InternalError'),
    name: 'internal-error',
    meta: {
      auth: false,
      title: 'message.errors.internalErrorTitle',
      breadcrumb: null,
    },
  },
  {
    path: '*',
    component: () => import('@/views/errors/NotFound'),
    meta: {
      auth: false,
      title: 'message.errors.notFoundTitle',
      breadcrumb: null,
    },
  },
];
