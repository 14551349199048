/**
 * Filters Module
 */

import lead from './lead';

// state
const state = {};

// getters
const getters = {};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
  modules: {
    lead,
  },
};
