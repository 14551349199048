/**
 * Loan Filters Module
 */

// getters
const getters = {
  copy: (state) => {
    return JSON.parse(JSON.stringify(state));
  },
  search: (state) => {
    return state.custom.search;
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  set(state, value) {
    state = value;
  },
  setSearch(state, value) {
    state.custom.search = value;
  },
  init(state) {
    Object.assign(state, initialize());
  },
};

// helpers
function initialize() {
  return {
    default: {
      postalCodes: {
        value: [],
        column: 'postalCode',
        condition: 'whereIn',
        type: 'whereIn',
      },
      provinces: {
        value: [],
        column: 'province',
        condition: 'whereIn',
        type: 'whereIn',
      },
    },
    custom: {
      search: null,
    },
  };
}

export default {
  state: initialize,
  getters,
  actions,
  mutations,
  namespaced: true,
};
