import MenuConfig from '@/constants/MenuConfig';
// import { RoutePermissions } from '@/helpers/data';

export default [
  {
    path: '/',
    component: () => import('@/layouts/HorizontalLayout'),
    meta: {
      menuName: MenuConfig.home.name,
    },
    redirect: '/leads',
    children: [
      {
        path: '/leads',
        component: () => import('@/views/loan/Leads'),
        meta: {
          auth: true,
          menuName: MenuConfig.leads.name,
          // access: RoutePermissions.HOME,
          title: 'message.loan.leadsTitle',
        },
      },
      {
        path: '/loans',
        component: () => import('@/views/loan/List'),
        meta: {
          auth: true,
          menuName: MenuConfig.loans.name,
          // access: RoutePermissions.HOME,
          title: 'message.loan.loanListTitle',
        },
      },
      {
        path: '/broker/dashboard',
        component: () => import('@/views/broker/Dashboard'),
        meta: {
          auth: true,
          menuName: MenuConfig.broker.name,
          // access: RoutePermissions.HOME,
          title: 'message.broker.title',
        },
      },
      {
        path: '/loan/:id',
        component: () => import('@/views/loan/File'),
        meta: {
          auth: true,
          menuName: MenuConfig.loan.name,
          // access: RoutePermissions.LOAN_FILE,
          title: 'message.loan.file',
        },
      },
      {
        path: '/settings',
        component: () => import('@/layouts/SettingsLayout'),
        meta: {
          menuName: MenuConfig.settings.name,
        },
        redirect: '/settings/user',
        children: [
          {
            path: '/settings/user',
            component: () => import('@/components/settings/user/General'),
            meta: {
              auth: true,
              menuName: MenuConfig.settings.name,
              title: 'message.shared.configuration',
              // access: RoutePermissions.USER,
            },
          },
          {
            path: '/settings/professional',
            component: () => import('@/components/settings/user/Professional'),
            meta: {
              auth: true,
              menuName: MenuConfig.settings.name,
              title: 'message.shared.configuration',
              // access: RoutePermissions.USER,
            },
          },
          // {
          //   path: '/settings/organization/:section?',
          //   component: () =>
          //     import('@/views/settings/organization/Configuration'),
          //   beforeEnter(to, from, next) {
          //     if (!to.params.section) {
          //       next('/settings/organization/brand');
          //     }

          //     next();
          //   },
          //   meta: {
          //     auth: true,
          //     menuName: MenuConfig.settings.name,
          //     title: 'message.shared.configuration',
          //     // access: RoutePermissions.ORGANIZATION_CONFIGURATION,
          //   },
          // },
        ],
      },
    ],
  },
];
