import Vue from 'vue';
import Vuex from 'vuex';

// Store Modules
import settings from './modules/settings';
import auth from './modules/auth';
import operation from './modules/operation';
import appdata from './modules/appdata';
import user from './modules/user';
import filters from './modules/filters';
import version from './modules/version';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    settings,
    auth,
    operation,
    appdata,
    user,
    filters,
    version,
  },
  strict: process.env.NODE_ENV !== 'production',
});
