import axios from 'axios';
import router from '@/router';
import store from '@/store';

const $axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'content-type': 'application/json',
    accept: 'application/json',
  },
});

$axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) config.headers['Authorization'] = `Bearer ${token}`;
  const appVersion = localStorage.getItem('version');
  if (appVersion) config.headers['app-version'] = appVersion;
  return config;
});

$axios.interceptors.response.use(
  (response) => {
    checkAppVersion(response.headers);
    validate(response.data);
    return response;
  },
  (error) => {
    if (!error.response) {
      // Unhandled error
      router.push('/error').catch(() => {});
    } else if (error.response.status === 401) {
      // 401: clear user to avoid redirection loop for any storaged user and expired tokens
      router.push('/session-expired').catch(() => {});
    } else if (error.response.status === 403) {
      // 403: security break, trying to access private endpoint
      router.push('/error').catch(() => {});
    } else if (error.response.status === 404) {
      // 404: not found
      router.push('/not-found').catch(() => {});
    }

    return Promise.reject(error);
  }
);

const validate = (response) => {
  if (response && 'validate' in response) {
    if (response.validate.required) {
      let user = store.getters['auth/user'];

      if (user) {
        if (response.validate.role != user.role) {
          store.commit('auth/logout');
          router.push('/error');
        }

        if (!user.manager) {
          if (response.validate.manager) {
            store.commit('auth/logout');
            router.push('/error');
          }
        }
      }
    }
  }
};

const checkAppVersion = (headers) => {
  const appVersion = headers['app-version'];
  const storedAppVersion = localStorage.getItem('version');

  if (appVersion && storedAppVersion && appVersion !== storedAppVersion) {
    localStorage.setItem('version', appVersion);
    store.commit('version/setRefreshApp', true);
  }
};

export default $axios;
