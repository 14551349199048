import validator from './validator';
import session from './session';
import errors from './errors';
import shared from './shared';
import dashboard from './dashboard';
import menu from './menu';
import loan from './loan';
import config from './config';
import applicant from './applicant';
import broker from './broker';
import file from './file';
import secured from './secured';
import user from './user';
import multiselect from './multiselect';

export default {
  validator,
  session,
  errors,
  shared,
  dashboard,
  menu,
  loan,
  config,
  applicant,
  broker,
  file,
  secured,
  user,
  multiselect,
};
