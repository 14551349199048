export default {
  leadsTitle: 'Operaciones disponibles',
  leads: 'Operaciones disponibles',
  getLeadsError:
    'Ha ocurrido un error al intentar obtener el listado de operaciones',
  property: 'Propiedad',
  loan: 'Hipoteca',
  depositAmount: 'Aportación',
  loanAmount: 'Importe hipoteca',
  propertyValue: 'Precio propiedad',
  ltv: 'LTV',
  noLeads: 'No hay operaciones disponibles',
  noLoans: 'No hay operaciones',
  searchFields: 'Nombre / Email / Tlf / DNI',
  loanListTitle: 'Mis operaciones',
  loanList: 'Mis operaciones',
  file: 'Ficha de la operación',
  loadingError: 'Ha ocurrido un error al recuperar los datos de la operación',
  dti: 'DTI',
  propertyUse: 'Propiedad para',
  tierHeader: 'Comprar lead',
  buyLeadBy: 'Comprar lead por --price--',
  pickLoanSuccess: 'Se ha comprado el lead',
  pickLoanError: 'Ha ocurrido un error al intentar comprar el lead',
  tierDescription:
    'Compra el lead para poder descargar la documentación, ver los datos de contacto del cliente y empezar a trabajar con el.',
  pickLoanError_insufficientBalance:
    'No tienes suficiente crédito en tu monedero',
  notEnoughMoney: 'No tienes suficiente crédito en tu monedero',
  hideName: 'Nombre oculto',
  hideDocuments: 'Podrás ver la documentación asociada cuando compres el lead.',
  createdTimeDaysAgo: 'Hace --days-- día(s)',
  loanPostalCode: 'Código postal',
  selectLoanPostalCode: 'Filtra las operaciones por código postal',
  loanProvince: 'Provincia',
  selectLoanProvince: 'Filtra las operaciones por provincia',
  leadFilterDataError: 'Ha ocurrido un error cargando los filtros',
  totalResults: '--results-- resultado(s)',
  paginationInfo: 'Página --currentPage-- de --totalPages--',
};
