/**
 * Operation (loan) Module
 */
import Vue from 'vue';
import api from '@/api';
import Nprogress from 'nprogress';
import { i18n } from '@/main';

import loan from './loan';
import property from './property';
import applicants from './applicants/index';

// getters
const getters = {
  menu: (state) => {
    return state.menu;
  },
};

// actions
const actions = {
  async load(context, payload) {
    // Already on loading process
    if (context.state.loading) return;

    Nprogress.start();

    context.commit('setLoading', true);
    context.commit('loading', null);

    modules('main').forEach((key) =>
      context.commit('operation/' + key + '/loading', null, { root: true })
    );

    try {
      const response = await api.get(`operation/${payload.id}`);

      if (response.data.success) {
        const data = response.data;

        // Loan
        context.commit(
          'operation/loan/loaded',
          { loan: data.loan, tier: data.tier },
          { root: true }
        );

        // Applicants
        context.commit('operation/applicants/loaded', data.applicants, {
          root: true,
        });

        // Property
        context.commit(
          'operation/property/loaded',
          {
            data: data.property,
          },
          { root: true }
        );

        // Documents
        context.commit(
          'operation/applicants/documents/loaded',
          {
            list: data.documents,
          },
          { root: true }
        );

        // Operation
        context.commit('loaded');
      }
    } catch (error) {
      Vue.notify({
        group: 'api',
        type: 'error',
        text: i18n.t('message.loan.loadingError'),
      });

      context.commit('setLoading', false);
      modules('main').forEach((key) =>
        context.commit('operation/' + key + '/setLoading', false, {
          root: true,
        })
      );
    } finally {
      Nprogress.done();
    }
  },
};

// mutations
const mutations = {
  setLoading(state, value) {
    state.loading = value;
  },
  setLoaded(state, value) {
    state.loaded = value;
  },
  setUpdating(state, value) {
    state.updating = value;
  },
  setMenu(state, value) {
    state.menu = value;
  },
  loaded(state) {
    state.loading = false;
    state.loaded = true;
  },
  loading(state) {
    state.loaded = false;
    state.relatedLoaded = false;
  },
  init(state) {
    Object.assign(state, initialize());
  },
};

// helpers
function initialize() {
  return {
    loading: false,
    loaded: false,
    relatedLoaded: false,
    updating: false,
    menu: 'file',
  };
}

function modules(group) {
  let keys = [];

  if (group === 'main') {
    keys = ['loan', 'applicants', 'property'];
  }

  return keys;
}

export default {
  state: initialize,
  getters,
  actions,
  mutations,
  namespaced: true,
  modules: {
    loan,
    property,
    applicants,
  },
};
