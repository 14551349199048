import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Notifications from 'vue-notification';
import VueI18n from 'vue-i18n';
import PortalVue from 'portal-vue';
import messages from './i18n';
import { configure, extend, setInteractionMode } from 'vee-validate';
import {
  alpha,
  confirmed,
  double,
  email,
  ext,
  integer,
  is,
  max,
  max_value,
  min,
  min_value,
  required,
  size,
} from 'vee-validate/dist/rules';
import { idType, validDNI, validNIE, validCIF } from '@/helpers/helpers';
import Cleave from 'cleave.js';
import Oruga from '@oruga-ui/oruga';
import { bulmaConfig } from '@oruga-ui/theme-bulma';

// Import SCSS files globally
require('@/assets/scss/main.scss');
require('@/assets/scss/oruga-bulma.scss');

Vue.config.productionTip = false;

bulmaConfig.modal.scroll = 'clip';
Vue.use(Oruga, {
  ...bulmaConfig,
  iconPack: 'phosphor',
  customIconPacks: {
    phosphor: {
      iconPrefix: 'ph-',
      internalIcons: {
        eye: 'eye',
        'eye-off': 'eye-closed',
        'chevron-right': 'caret-right',
        'chevron-left': 'caret-left',
        'chevron-up': 'caret-up',
        'chevron-down': 'caret-down',
      },
    },
  },
});
Vue.use(Notifications);
Vue.use(VueI18n);
Vue.use(PortalVue);

export const i18n = new VueI18n({
  locale: store.getters.selectedLocale.locale,
  messages,
});

setInteractionMode('eager');

// Vee-Validate Rules
extend('alpha', alpha);
extend('confirmed', confirmed);
extend('double', double);
extend('email', email);
extend('ext', ext);
extend('integer', integer);
extend('is', is);
extend('min', min);
extend('min_value', min_value);
extend('max', max);
extend('max_value', max_value);
extend('required', required);
extend('size', size);

// Custom rules
extend('password', (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  let count = 0;

  count += value.length >= 6 ? 1 : 0;
  count += /[A-Za-z]/.test(value) ? 1 : 0;
  count += /\d/.test(value) ? 1 : 0;

  if (count >= 3) return true;

  return false;
});

extend('spanishId', (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  let valid = false;

  if (value) {
    // Ensure upcase and remove whitespace
    const str = value.toUpperCase().replace(/\s/, '');
    const type = idType(str);

    switch (type) {
      case 'dni':
        valid = validDNI(value);
        break;
      case 'nie':
        valid = validNIE(value);
        break;
      case 'cif':
        valid = validCIF(value);
        break;
    }
  }

  return valid;
});

extend('generalId', (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  let valid = false;

  if (value) {
    // Ensure upcase and remove whitespace
    const str = value.toUpperCase().replace(/\s/, '');

    // 6-20 alphabetic characters
    if (str.match(/^[a-zA-Z0-9]{6,20}$/)) {
      // not the same character in every position
      if (!str.match(/^(.)\1{1,}$/)) {
        valid = true;
      }
    }
  }

  return valid;
});

extend('phone', (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  const phone = value.toString().replace(/\s/g, '');

  // Check if phone
  if (phone.length !== 9 || !/^[679]{1}[0-9]{8}$/.test(phone)) {
    return false;
  }

  return true;
});

extend('phoneWithPrefix', (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  const phone = value.toString().replace(/\s/g, '').replace(/\(|\)/g, '');

  // Check if phone
  if (!/^[0-9]*$/.test(phone)) {
    return false;
  }

  if (phone.length > 20 || phone.length < 9) {
    return false;
  }

  return true;
});

extend('biggerThanDeposit', {
  params: ['target'],
  validate(value, { target }) {
    if (value && target) {
      return parseFloat(value) > parseFloat(target);
    }
    return true;
  },
});

extend('biggerThanPendingAmount', {
  params: ['target'],
  validate(value, { target }) {
    if (value && target) {
      return parseFloat(value) > parseFloat(target);
    }
    return true;
  },
});

extend('biggerThanFunds', {
  params: ['target'],
  validate(value, { target }) {
    if (value && target) {
      return parseFloat(value) >= parseFloat(target);
    }
    return true;
  },
});

extend('url', {
  validate(value) {
    if (value) {
      return /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_:+.~#?&/=]*)$/.test(
        value
      );
    }

    return false;
  },
});

configure({
  // this will be used to generate validation messages with locale.
  defaultMessage: (field, values) => {
    // values._field_ = i18n.t(`${field}`);
    return i18n.t(`message.validator.messages.${values._rule_}`, values);
  },
});

// Initialize static-dictionary values
store.dispatch('appdata/public');
if (store.state.auth.user) store.dispatch('appdata/private');

// Cleave.js directive
Vue.directive('cleave', {
  inserted: (el, binding) => {
    if (binding.value) {
      const input = el.querySelector('input');
      input.cleave = new Cleave(input, binding.value || {});
    }
  },
  update: (el) => {
    const event = new Event('input', { bubbles: true });
    setTimeout(function () {
      const input = el.querySelector('input');
      if (input.cleave && input.value !== input.cleave.properties.result) {
        input.value = input.cleave.properties.result;
        input.dispatchEvent(event);
      }
    }, 100);
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
