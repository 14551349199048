export default {
  profile: 'Ficha del titular',
  firstname: 'Nombre',
  lastname: 'Apellidos',
  fullname: 'Nombre completo',
  email: 'Email',
  phone: 'Teléfono',
  currentHomeUsage: 'Uso vivienda actual',
  address: 'Dirección',
  city: 'Ciudad',
  postalCode: 'Código postal',
  country: 'País',
  nationality: 'Nacionalidad',
  province: 'Región/Provincia',
  loanApplicantType: 'Tipo de identificación',
  idType: 'Tipo de identificación',
  idNumber: 'Número de identificación',
  newOperationIdNumber: 'DNI / NIE / Pasaporte',
  birthdate: 'Fecha de nacimiento',
  age: 'Edad',
  maritalStatus: 'Estado civil',
  employment: 'Tipo de contrato',
  'employment-short': 'Contrato',
  lender: 'Banco actual del cliente',
  profession: 'Profesión',
  employer: 'Nombre de la empresa',
  timeAtJob: 'Antigüedad empleo actual (años)',
  'timeAtJob-short': 'Antigüedad empleo (años)',
  payNumber: 'No. de pagos',
  yearsAtJob: 'Antigüedad (años)',
  timeInCareer: 'Antigüedad vida laboral (años)',
  yearPayments: 'Nº de pagas',
  monthlyIncome: 'Ingresos mensuales',
  'monthlyIncome-short': 'Ingresos',
  netMonthlyOtherIncome: 'Otros ingresos mensuales',
  netMonthlyIncome: 'Ingresos mensuales netos',
  lastDeclaredMonthlyIncome: 'Ingresos según renta (anual)',
  lastDeclaredYearDebt: 'Pagos según renta (anual)',
  netMonthlyDebt: 'Cuota préstamos pendientes',
  monthlyDebtRemoving: 'Gastos que cesan con la hipoteca',
  dependants: 'Hijos/personas a cargo',
  documentTypeSelect: 'Selecciona un tipo de documento',
  documentSizeLimit: 'El límite máximo es de ',
  documentEmpty: 'No se han adjuntado documentos',
  uploadDocuments: 'Agregar documentos',
  dropDocuments: 'Arrastra los archivos o haz clic para agregarlos',
  documentsField: 'Documentos',
  uploadMultipleDocuments:
    'Puedes subir varios documentos al mismo tiempo siempre que sean del tipo que has seleccionado',
  uploadDocumentErrorList: 'Error al subir los siguientes documentos',
  uploadedDocuments: 'Documentos subidos',
  documents: 'Documentos',
  addSecondaryApplicantSuccess:
    'Se ha añadido al segundo titular correctamente',
  addSecondaryApplicantError:
    'Ha ocurrido un error al intentar añadir al segundo titular',
  addSecondaryHeader: 'Añadir segundo titular',
  downloadZip: 'Descargar todos (.zip)',
};
