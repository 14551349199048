export default {
  yes: 'Si',
  no: 'No',
  since: 'Desde',
  until: 'Hasta',
  new: 'Nuevo',
  add: 'Añadir',
  edit: 'Editar',
  delete: 'Eliminar',
  continue: 'Continuar',
  end: 'Finalizar',
  save: 'Guardar',
  create: 'Crear',
  send: 'Enviar',
  download: 'Descargar',
  simulate: 'Simular',
  reset: 'Reset',
  start: 'Empezar',
  back: 'Volver',
  goBack: 'Volver atrás',
  upload: 'Guardar',
  replace: 'Sobreescribir',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  update: 'Actualizar',
  updated: 'Registro actualizado',
  created: 'Registro creado',
  deleted: 'Registro eliminado',
  completed: 'Completada',
  onGoing: 'En curso',
  sending: 'Enviando',
  request: 'Solicitar',
  agree: 'De acuerdo',
  accept: 'Acepto',
  close: 'Cerrar',
  date: 'Fecha',
  datePlaceholder: 'Seleccionar fecha',
  saveChanges: 'Guardar cambios',
  postSuccess: 'Registro guardado',
  createSuccess: 'Registro creado',
  updateSuccess: 'Registro actualizado',
  uploadSuccess: 'Archivo(s) subido(s)',
  deleteFileSuccess: 'Archivo eliminado',
  deleteFileError: 'Error al eliminar el archivo',
  emailSuccess: 'El correo ha sido enviado',
  smsSuccess: 'El SMS ha sido enviado',
  uploadWarning: 'Ocurrió un error al subir alguno de los archivos',
  years: 'años',
  months: 'meses',
  month: 'mes',
  day: 'día',
  days: 'días',
  error: 'Ha ocurrido un error',
  errorWord: 'Error',
  postError: 'Error al guardar los datos',
  createError: 'Error al crear el registro',
  updateCostsError: 'Error al calcular los costes',
  createTokenError: 'Error al generar el enlace',
  createTokenSuccess: 'Enlace generado correctamente',
  updateError: 'Error al guardar los cambios',
  deleteError: 'Error al eliminar el registro',
  loadDataError: 'Error al recuperar los datos',
  appLoadDataError:
    'Error al cargar la configuración, por favor recarga la página y vuelve a intentarlo',
  downloadError: 'Error al descargar',
  uploadError: 'Error al subir el archivo',
  emailError: 'Ocurrió un error al enviar el correo',
  smsError: 'Ocurrió un error al enviar el SMS',
  ongoingRequest: 'Ya hay una petición en curso',
  waitingForToken: 'Generando token',
  optional: 'Opcional',
  sinceTime: 'Desde hace',
  next: 'Próximo',
  total: 'Total',
  won: 'Ganadas',
  lost: 'Perdidas',
  select: 'Seleccionar',
  stage: 'Stage',
  public: 'Pública',
  logout: 'Salir',
  account: 'Cuenta',
  lastUpdate: 'Última actualización',
  addDocuments: 'Agregar documentos',
  left: 'Falta',
  leftMultiple: 'Faltan',
  ago: 'Hace',
  today: 'Hoy',
  tomorrow: 'Mañana',
  daysFrom: 'En -days- días',
  updating: 'Actualizando',
  newVersion: 'Hemos encontrado una actualización',
  disable: 'Desactivar',
  enable: 'Activar',
  noAddedData: 'No indicado',
  copyLink: 'Copiar enlace',
  copyLinkSuccess: '¡Enlace copiado!',
  pdf: 'PDF',
  xlsx: 'XLSX',
  configuration: 'Configuración',
  refreshApp: 'Refrescar página',
  user: 'usuario',
  users: 'usuarios',
  settings: 'Configuración',
  availableCredit: 'Crédito disponible',
};
